<template>
  <b-tabs content-class="mt-3">
    <images-tab
      @refresh-data="refreshTabData"
      :data="tabsData.images"
    />
    <videos-tab
      @refresh-data="refreshTabData"
      :data="tabsData.videos"
    />
    <files-tab
      @refresh-data="refreshTabData"
      :data="tabsData.files"
    />
    <related-articles-tab
      @refresh-data="refreshTabData"
      :data="tabsData.relatedArticles"
    />
    <log-tab
      :entity-id="entityId"
      entity-type="article"
    />
  </b-tabs>
</template>

<script>
import {
  BTabs,
} from 'bootstrap-vue'
import imagesTab from '@/views/pages/_components/_tab/_article/_update/images.vue'
import videosTab from '@/views/pages/_components/_tab/_article/_update/videos.vue'
import filesTab from '@/views/pages/_components/_tab/_article/_update/files.vue'
import relatedArticlesTab from "@/views/pages/_components/_tab/_article/_update/relatedArticles"
import logTab from '@/views/pages/_components/_tab/_log/_list.vue'

export default {
  components: {
    BTabs,
    imagesTab,
    videosTab,
    filesTab,
    relatedArticlesTab,
    logTab,
  },
  props: {
    attributes: [],
    entityId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tabsData: []
    }
  },
  watch: {
    attributes: {
      handler(value) {
        let tmp = {
          images: [],
          videos: [],
          files: [],
          relatedArticles: [],
        }

        if (value.images.length) tmp.images = value.images
        if (value.videos.length) tmp.videos = value.videos
        if (value.files.length) tmp.files = value.files
        if (value.relatedArticles.length) tmp.relatedArticles = value.relatedArticles

        this.tabsData = tmp
      }
    }
  },
  methods: {
    refreshTabData() {
      this.$emit('refresh-tabs-data')
    }
  }
}
</script>

<style scoped>

</style>