<template>
  <b-tab>
    <template #title>
      <feather-icon
          icon="FileIcon"
          size="21"
      />
      <strong>
        Kapcsolódó írások
      </strong>
    </template>
    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
<!--        <b-row class="mb-3">-->
<!--          <b-col>-->
<!--            <strong><h5>Rövid ismertető a használattal kapcsolatban ...</h5></strong>-->
<!--          </b-col>-->
<!--        </b-row>-->
        <b-row class="mb-5">
          <b-col sm="2">
            Kapcs. írás hozzáadása a listához:
          </b-col>
          <b-col sm="4">
            <v-select
                v-model="selectedRelatedArticle"
                label="title"
                :options="articleOptions"
            />
          </b-col>
          <b-col sm="2">
            <b-button
                variant="primary"
                size="md"
                class="float-left"
                :disabled="!selectedRelatedArticle"
                @click.prevent="addRelatedArticle"
            >
              Mentés
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <draggable
                v-model="tabData"
                class="list-group list-group-flush cursor-move"
                @change="updateArticlesList"
            >
              <transition-group
                  type="transition"
                  name="flip-list"
              >
                <b-col
                    sm="12"
                    v-for="listItem in tabData"
                    :key="listItem.id"
                    class="dd-item mb-1 p-1"
                    style="border: 1px solid grey;"
                >
                  <b-row>
                  <b-col sm="1">{{ listItem.id }}</b-col>
                  <b-col :html="listItem.title" sm="4" />
                  <b-col sm="6">{{ listItem.lead }}</b-col>
                  <b-col sm="1">
                    <b-button
                        variant="danger"
                        size="sm"
                        class="ml-1"
                        @click.prevent="deleteRelatedArticle(listItem.id)"
                    >
                      <feather-icon
                          icon="TrashIcon"
                          size="15"
                      />
                    </b-button>
                  </b-col>
                  </b-row>
                </b-col>
              </transition-group>
            </draggable>
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </b-tab>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BOverlay,
  BForm,
  BCardText,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCardCode,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BOverlay,
    BForm,
    BCardText,
    BListGroupItem,
    vSelect,
  },
  props: {
    data: []
  },
  data() {
    return {
      loading: false,
      tabData: [],
      selectedRelatedArticle: null,
      articleOptions: [],
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
      },
      deep: true
    },
  },
  created() {
    this.$store.dispatch('fetchArticlesForSelect').then(response => {
      this.articleOptions = response

      if (this.articleOptions.length){
        this.selectedRelatedArticle = this.articleOptions[0]
      }
    })
  },
  methods: {
    addRelatedArticle() {
      this.showLoadBar()

      const fd = {
        'id': this.$route.params.id,
        'relatedId': this.selectedRelatedArticle.value,
      }

      this.$store.dispatch('addRelatedArticle', fd).then(() => {
        this.$emit('refresh-data')
        this.$helpers.showSuccessToast()
      }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    deleteRelatedArticle(relatedId) {
      this.$bvModal
          .msgBoxConfirm('Biztosan törölni szeretnéd a kapcs. írást?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Igen',
            cancelTitle: 'Nem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const fd = {
                'id': this.$route.params.id,
                'relatedId': relatedId,
              }

              this.showLoadBar()

              // törlés
              this.$store.dispatch('deleteRelatedArticle', fd)
                  .then(() => {
                    this.$emit('refresh-data')
                    this.$helpers.showSuccessToast()
                  }).catch(error => {
                if (error.response.status === 422) {
                  this.$helpers.showErrorToast(error.response.data.errors[0])
                } else {
                  this.$helpers.showErrorToast(error.toString())
                }
              }).finally(() => {
                this.hideLoadBar()
              })
            }
          })
    },
    updateArticlesList() {
      const fd = {
        'id': this.$route.params.id,
        'list': this.tabData,
      }

      this.showLoadBar()

      // törlés
      this.$store.dispatch('updateRelatedArticles', fd)
          .then(() => {
            this.$emit('refresh-data')
            this.$helpers.showSuccessToast()
          }).catch(error => {
        if (error.response.status === 422) {
          this.$helpers.showErrorToast(error.response.data.errors[0])
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style  lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.dd-item {
  transition: all 1s
}

.sortable-ghost {
  border: 1px solid green !important;
  background: #d1eed1;
}
</style>
