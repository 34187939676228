<template>
  <b-tab
    active
  >
    <template #title>
      <feather-icon
          icon="ImageIcon"
          size="21"
      />
      <strong>
        Képek
        &nbsp<b-badge v-if="dangerCnt > 0" variant="danger">{{ dangerCnt }}</b-badge>
      </strong>
    </template>

    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-row>
          <b-col cols="12" class="mb-3">
            <div class="mb-1">Csoportos képfeltöltéshez húzd rá, vagy kattintás után tallózd be a képeket a lenti beviteli mező segítségével.</div>
            <b-row>
              <b-col sm="9">
                <b-form-file
                    v-model="images"
                    placeholder="Válassz vagy húzd ide a fájlokat"
                    drop-placeholder="A fájlokat húzd ide ..."
                    accept="
                    image/bmp,
                    image/gif,
                    image/jpeg,
                    image/png,
                    image/webp
                    "
                    multiple
                />
              </b-col>
              <b-col sm="3">
                <b-button
                    v-if="images.length"
                    variant="danger"
                    class="float-left mr-1"
                    @click.prevent="() => {images = []}"
                >
                  <feather-icon
                      icon="Trash2Icon"
                      size="15"
                  />
                </b-button>
                <b-button
                    variant="primary"
                    type="submit"
                    :disabled="!images.length"
                    @click.prevent="submitFiles"
                >
                  Csoportos feltöltés
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- képek renderelése -->
          <b-col
              v-for="(item, index) in tabData"
              :key="item.id"
              sm="2"
          >
            <!-- mentett kép? -->
            <b-card
                v-if="item.saved"
                overlay
                :img-src="item.url"
                :title="item.name"
                img-top
            >
              <b-card-text>
                <div class="mb-0">
                  <b-button
                      variant="success"
                      size="sm"
                      @click.prevent="replaceFileModal(item.id, item.name, item.file_id)"
                  >
                    <feather-icon
                        icon="RefreshCwIcon"
                        size="15"
                    />
                  </b-button>
                  <b-button
                      variant="danger"
                      size="sm"
                      class="ml-1"
                      @click.prevent="deleteFile(item.file_id)"
                  >
                    <feather-icon
                        icon="TrashIcon"
                        size="15"
                    />
                  </b-button>
                  <b-link
                    :href="item.download_url"
                    target="_blank"
                  >
                    <b-button
                        variant="primary"
                        size="sm"
                        class="ml-1"
                    >
                      <feather-icon
                          icon="DownloadIcon"
                          size="15"
                      />
                    </b-button>
                  </b-link>
                </div>
              </b-card-text>
            </b-card>
            <b-card
                v-else
                overlay
                :img-src="require('@/assets/images/fallback-image-200-200.png')"
                :title="item.name"
                img-top
                class="position-static"
                @click.prevent="uploadFileModal(item.name, item.subtitle)"
            >
              <b-button
                  variant="success"
                  size="sm"
                  class="float-left"
                  @click.prevent="uploadFileModal(item.name)"
              >
                <feather-icon
                    icon="ShareIcon"
                    size="15"
                />
              </b-button>
            </b-card>
          </b-col>
      </b-row>
      </b-overlay>
    </div>

    <!--  add file modal  -->
    <b-modal
        id="add-file-modal"
        size="sm"
        :title="this.tmp.name"
        hide-footer
    >
<!--      <b-row class="mb-1">-->
<!--        <b-col>-->
<!--          <h6>Rövid útmutató</h6>-->
<!--          <p>-->
<!--            .... [lorem ...]-->
<!--          </p>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <validation-observer ref="imageUploadValidationObserver">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group
                  label="Kép"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kép"
                    rules="required"
                    vid="title"
                >
                  <b-form-file
                      v-model="tmp.image"
                      :state="Boolean(tmp.image)"
                      placeholder="Tallózz ki, vagy húzz ide egy állományt"
                      drop-placeholder="Húzd ide ..."
                      accept="
                      image/bmp,
                      image/gif,
                      image/jpeg,
                      image/png,
                      image/webp
                      "
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--   close   -->
      <b-button variant="danger" class="mt-1 float-left" @click="closeModal('add-file-modal')">
        Bezárás
      </b-button>
      <!--   save   -->
      <b-button variant="success" class="mt-1 float-right" @click="sendImage">
        Mentés
      </b-button>
    </b-modal>

    <!--  replace file modal  -->
    <b-modal
        id="replace-file-modal"
        size="sm"
        :title="'Kép cseréje(' + this.tmp.name + ')'"
        hide-footer
    >
<!--      <b-row class="mb-1">-->
<!--        <b-col>-->
<!--          <h6>Rövid útmutató</h6>-->
<!--          <p>-->
<!--            .... [a képet töröljük és feltöltjük ...]-->
<!--          </p>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <validation-observer ref="imageReplaceValidationObserver">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group
                  label="Kép"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kép"
                    rules="required"
                    vid="title"
                >
                  <b-form-file
                      v-model="tmp.image"
                      :state="Boolean(tmp.image)"
                      placeholder="Tallózz ki, vagy húzz ide egy állományt"
                      drop-placeholder="Húzd ide ..."
                      accept="
                      image/bmp,
                      image/gif,
                      image/jpeg,
                      image/png,
                      image/webp
                      "
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--   save   -->
      <b-button variant="success" class="mt-1 float-left" @click="replaceImage">
        Mentés
      </b-button>
      <!--   close   -->
      <b-button variant="danger" class="mt-1 float-right" @click="closeModal('replace-file-modal')">
        Bezárás
      </b-button>
    </b-modal>
  </b-tab>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BButton,
  BFormFile,
  BOverlay,
  BModal,
  BForm,
  BFormGroup,
  BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    BFormFile,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    data: []
  },
  data() {
    return {
      loading: false,
      tabData: [],
      dangerCnt: 0,
      images: [],
      required,
      tmp: {
        id: null,
        name: null,
        alt: '',
        image: null,
      },
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      }
    }
  },
  computed: {
    hasLoading() {
      return this.loading
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      this.dangerCnt = 0
      if (this.tabData.length) {
        Object.keys(this.tabData).forEach(key => {
          // hány darab feldolgozandó kép van?
          if (!this.tabData[key]['saved']) {
            this.dangerCnt += 1
          }
        })
      }
    },
    submitFiles() {
      if (this.images.length) {
        this.showLoadBar()
        let all = 0
        let success = 0
        Object.keys(this.images).forEach(key => {
          const fd = new FormData();
          fd.append('modelId', this.$route.params.id)
          fd.append('modelType', 'article')
          fd.append('file', this.images[key])

          this.$store.dispatch('storeModelImage', fd).
          then(() => {
            success += 1
          }).catch(error => {
            if (error.response.status === 422) {
              this.$helpers.showErrorToast(error.response.data.errors[0])
            } else if(error.response.status === 413){
              this.$helpers.showErrorToast('A feltölteni kívánt fájl(ok) túl nagy(ok).')
            }else{
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            all += 1
            if (success === this.images.length) {
              this.images = []
              this.$emit('refresh-data')
              this.$helpers.showSuccessToast()
              this.hideLoadBar()
            }

            if (all === this.images.length) {
              this.hideLoadBar()
            }
          })
        })
      }
    },
    uploadFileModal(imageName, alt) {
      this.tmp.name = imageName
      this.tmp.alt = alt
      this.$bvModal.show('add-file-modal')
    },
    sendImage() {
      this.$refs.imageUploadValidationObserver.validate().then(success => {
        if (success) {
          this.showLoadBar()
          const fd = new FormData()
          fd.append('customFileName', this.tmp.name)
          fd.append('modelId', this.$route.params.id)
          fd.append('modelType', 'article')
          fd.append('alt', this.tmp.alt)
          fd.append('file', this.tmp.image)

          this.$store.dispatch('storeModelImage', fd).then(() => {
            this.$bvModal.hide('add-file-modal')
            this.resetTempData()
            this.$emit('refresh-data')
            this.$helpers.showSuccessToast()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$helpers.showErrorToast(error.response.data.errors[0])
            } else if(error.response.status === 413){
              this.$helpers.showErrorToast('A feltölteni kívánt fájl(ok) túl nagy(ok).')
            }else{
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.hideLoadBar()
          })
        }
      })
    },
    replaceFileModal(imageId, imageName, fileId) {
      this.tmp.id = imageId
      this.tmp.fileId = fileId
      this.tmp.name = imageName
      this.$bvModal.show('replace-file-modal')
    },
    replaceImage() {
      this.$refs.imageReplaceValidationObserver.validate().then(success => {
        if (success) {
          this.showLoadBar()

          const fd = new FormData()
          fd.append('modelId', this.$route.params.id)
          fd.append('modelType', 'article')
          fd.append('fileId', this.tmp.fileId)

          // törlés
          this.$store.dispatch('deleteFile', fd).then(() => {

            const fd = new FormData()
            fd.append('customFileName', this.tmp.name)
            fd.append('modelId', this.$route.params.id)
            fd.append('modelType', 'article')
            fd.append('alt', this.tmp.alt)
            fd.append('file', this.tmp.image)

            this.$store.dispatch('storeModelImage', fd).then(() => {
              this.$bvModal.hide('replace-file-modal')
              this.resetTempData()
              this.$emit('refresh-data')
              this.$helpers.showSuccessToast()
            }).catch(error => {
              if (error.response.status === 422) {
                this.$helpers.showErrorToast(error.response.data.errors[0])
              } else if(error.response.status === 413){
                this.$helpers.showErrorToast('A feltölteni kívánt fájl(ok) túl nagy(ok).')
              }else{
                this.$helpers.showErrorToast(error.toString())
              }
            }).finally(() => {
              this.hideLoadBar()
            })

          }).catch(error => {
            this.$helpers.showErrorToast(error.toString())
            this.hideLoadBar()
          })
        }
      })
    },
    deleteFile(fileId, showLoadBar = true) {
      this.$bvModal
          .msgBoxConfirm('Biztosan törölni szeretnéd a képet?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Igen',
            cancelTitle: 'Nem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const fd = new FormData()
              fd.append('modelId', this.$route.params.id)
              fd.append('modelType', 'article')
              fd.append('fileId', fileId)

              if (showLoadBar) this.showLoadBar()
              // axios
              this.$store.dispatch('deleteFile', fd).then(() => {
                this.$emit('refresh-data')
                this.$helpers.showSuccessToast(`Sikeres törlés (${fileId})`)
              }).finally(() => {
                if (showLoadBar) this.hideLoadBar()
              })
            }
          })
    },
    // külön metódus kell, hogy kiüssük a tem datat is
    closeModal(modalName) {
      this.$bvModal.hide(modalName)
      this.resetTempData()
    },
    resetTempData() {
      this.tmp = {
        id: null,
        name: null,
        alt: '',
        image: null,
      }
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>

</style>
